import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="info" mdxType="Alert">
      <p>{`In 2019, we released a new major version of `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {`. This document
describes how you can upgrade from versions 3.x to versions 4.x and higher.`}</p>
      <p>{`If you are upgrading from version 4.x and higher to the latest version, please
consult the
`}
        <a {...{
          "href": "https://github.com/maxmind/geoipupdate/releases",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`changelog of the latest release`}</a>
        {`
for information.`}</p>
    </Alert>
    <div {...{
      "id": "toc-what-changed"
    }}><h2 {...{
        "id": "what-changed",
        "parentName": "div"
      }}>{`What changed`}</h2>
      <p {...{
        "parentName": "div"
      }}><inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` 4`}
        {`.`}
        {`x was a rewrite of the old `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` and started from a new
modern code base. It supports the same core functionality and config file format
as before.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The biggest change is support for additional platforms, notably Windows.`}</p></div>
    <div {...{
      "id": "toc-notable-changes"
    }}><h2 {...{
        "id": "notable-changes",
        "parentName": "div"
      }}>{`Notable changes`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`There are changes you should be aware of if you’re upgrading from older
versions.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`If you’re coming from `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` 2`}
        {`.`}
        {`x:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}>{`We removed support for the configuration options `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`Protocol`}</inlineCode>
          {`,
`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`SkipPeerVerification`}</inlineCode>
          {`, and `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`SkipHostnameVerification`}</inlineCode>
          {`. `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`geoipupdate`}</inlineCode>
          {` now
always uses HTTPS and always verifies certificates. We ignore these options if
present.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`The configuration file must have the `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`AccountID`}</inlineCode>
          {` (or the deprecated `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`UserId`}</inlineCode>
          {`)
when downloading a paid database. Previously, when downloading the GeoIP
Legacy Country database, you were able to only provide the `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`LicenseKey`}</inlineCode>
          {`. Now
you must provide both `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`AccountID`}</inlineCode>
          {` and `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`LicenseKey`}</inlineCode>
          {`.`}</li>

      </ul></div>
    <div {...{
      "id": "toc-confirm-your-version"
    }}><h2 {...{
        "id": "confirm-your-version",
        "parentName": "div"
      }}>{`Confirm your version`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`You can do this by running `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate -V`}</inlineCode>
        {`:`}</p>
      <pre {...{
        "parentName": "div"
      }}><code {...{
          "className": "language-cli",
          "parentName": "pre"
        }}>{`geoipupdate -V
> geoipupdate 3.1.1
`}</code></pre>
      <p {...{
        "parentName": "div"
      }}>{`If this says something like `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate 4.0.0`}</inlineCode>
        {` then you’re already running 4.x.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`If this says something like `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`command not found`}</inlineCode>
        {` then you may not have
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` installed. Please see our
`}
        <a {...{
          "href": "/geoip/updating-databases#1-install-geoip-update",
          "parentName": "p"
        }}>{`installation guide`}</a>
        {` for more
information.`}</p></div>
    <div {...{
      "id": "toc-find-your-config-file"
    }}><h2 {...{
        "id": "find-your-config-file",
        "parentName": "div"
      }}>{`Find your config file`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Typically this will be `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/etc/GeoIP.conf`}</inlineCode>
        {` or `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/usr/local/etc/GeoIP.conf`}</inlineCode>
        {`.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`One way to do this is to run `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` in verbose mode:`}</p>
      <pre {...{
        "parentName": "div"
      }}><code {...{
          "className": "language-cli",
          "parentName": "pre"
        }}>{`geoipupdate -v
> geoipupdate 3.1.1
> Opened License file /etc/GeoIP.conf
> ...
`}</code></pre>
      <p {...{
        "parentName": "div"
      }}>{`In this case the config file is `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/etc/GeoIP.conf`}</inlineCode>
        {`.`}</p></div>
    <div {...{
      "id": "toc-install-geoipupdate-4x"
    }}><h2 {...{
        "id": "install-geoipupdate-4x",
        "parentName": "div"
      }}>{`Install geoipupdate 4.x`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Follow the instructions in our
`}
        <a {...{
          "href": "/geoip/updating-databases#1-install-geoip-update",
          "parentName": "p"
        }}>{`installation guide`}</a>
        {` for how
to do this.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`If you previously installed it via our PPA, you should be able to upgrade
through `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`apt`}</inlineCode>
        {`.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`If you previously installed it via `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`make install`}</inlineCode>
        {`, you should determine where
it’s installed so you don’t end up with conflicting versions. You can run this
command to check where it is:`}</p>
      <pre {...{
        "parentName": "div"
      }}><code {...{
          "className": "language-cli",
          "parentName": "pre"
        }}>{`which geoipupdate
> /usr/bin/geoipupdate
`}</code></pre>
      <p {...{
        "parentName": "div"
      }}>{`In this case `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` is installed to `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/usr/bin/geoipupdate`}</inlineCode>
        {` so you’d want
to install there again.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`After you’ve installed it, confirm by running `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate -V`}</inlineCode>
        {` that you’re
running 4.x.`}</p></div>
    <div {...{
      "id": "toc-test-it"
    }}><h2 {...{
        "id": "test-it",
        "parentName": "div"
      }}>{`Test it`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Run `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` in verbose mode to verify it’s using the config file you
expect and completes without error:`}</p>
      <pre {...{
        "parentName": "div"
      }}><code {...{
          "className": "language-cli",
          "parentName": "pre"
        }}>{`geoipupdate -v
> Using config file /etc/GeoIP.conf
> ...
`}</code></pre>
      <p {...{
        "parentName": "div"
      }}>{`If it’s using a config file from a different spot than you expect, either move
your config to that location or pass the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`-f`}</inlineCode>
        {` flag when running `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` to
tell it where to find the config.`}</p></div>
    <div {...{
      "id": "toc-check-cron"
    }}><h2 {...{
        "id": "check-cron",
        "parentName": "div"
      }}>{`Check cron`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`If you configured `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoipupdate`}</inlineCode>
        {` to run from cron then it is a good idea to
confirm it will continue to work. In particular you should check the command in
your cron configuration will use the config file you expect. You can run the
command from your crontab with the verbose flag (`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`-v`}</inlineCode>
        {`) to verify this.`}</p></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      